import React, { useState } from 'react';
import './App.css';
import ReactCardFlip from "react-card-flip";
import { strings } from "./localization";
const App: React.FC = () => {
    const [lang, setLang] = useState<boolean>(false);
    return (
        <React.Fragment>
            <nav className="navbar navbar-expand-sm navbar-light bg-light container-fluid d-flex">
                <div className="navbar-header">
                    <a className="navbar-brand"
                        href="/">
                        <img src="img/logo_na_pruhlednem_poz.png" alt='KasmaCleanLogo'
                            style={{ width: "auto", height: "60px", display: "inline-block" }}
                        />
                    </a>
                </div>
                <button className="navbar-toggler" type="button"
                    data-toggle="collapse"
                    data-target="#navbarTogglerDemo03" aria-controls="navbarTogglerDemo03"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <i className="fas fa-bars"></i>
                </button>
                <div className="container">
                    <div className="collapse navbar-collapse float-right" id="navbarTogglerDemo03">
                        <ul className="navbar-nav ml-auto mr-0 mr-lg-5 pr-3 mt-2 mt-lg-0">
                            <li className="nav-item" data-toggle="collapse"
                                data-target=".navbar-collapse.show">
                                <a className="nav-link" href="#about">{strings.aboutMenu}</a>
                            </li>
                            <li className="nav-item" data-toggle="collapse"
                                data-target=".navbar-collapse.show">
                                <a className="nav-link" href="#sluzby" id="navbardrop">
                                    {strings.ServicesMenu}
                                </a>
                            </li>
                            <li className="nav-item" data-toggle="collapse"
                                data-target=".navbar-collapse.show">
                                <a className="nav-link" href="#kontakt">{strings.contactUsMenu}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="ml-2"><a onClick={() => {
                    setLang(false);
                    strings.setLanguage("cze")
                }} className={!lang ? "active" : ""}>CZ</a>
                    <a onClick={() => {
                        setLang(true);
                        strings.setLanguage("de")
                    }} className={lang ? "active" : ""}>DE</a>
                </div>
            </nav>
            <div className="container px-0 main">
                <div className="title text-right pt-3">
                    <p style={{ fontSize: 22 }} dangerouslySetInnerHTML={{ __html: strings.bannerTitle }} />
                </div>
            </div>
            <div id="about" className="container px-0 full-size text-center centered-content">
                <div className="row mx-auto">
                    <div className="col-12 mt-4">
                        <h2 className="h1-responsive fw-500 text-center pt-4">{strings.homeTitle}</h2>
                        <div className="d-flex align-content-center justify-content-center flex-wrap">
                            <div className="col-10">
                                <p>{strings.homeText}</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-4">
                        <h2 className="h1-responsive fw-500 text-center pt-4">{strings.aboutTitle}</h2>
                        <div>
                            <p>{strings.about}</p>
                        </div>
                    </div>
                    <div className="col-12 p-0 d-flex align-content-center colorful mt-4">
                        <div className="d-flex flex-column p-5 color-code">
                            <h4 className="mt-auto" style={{ fontWeight: 400 }}>{strings.colorCode}</h4>
                            <p className="mb-auto">{strings.colorCodeText}</p>
                        </div>
                    </div>
                    <div id="sluzby" className="col-12 my-4">
                        <h2 className="h1-responsive fw-500 text-center pt-4">{strings.services}</h2>
                        <div className="align-self-center">
                            <p dangerouslySetInnerHTML={{ __html: strings.servicesText }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="container px-0 full-size">
                <div className="row mx-auto">
                    <div className="col-12 col-md-6 col-xl-4">
                        <FlipCardService imgURL={"img/podlaha_mop.jpg"} title={strings.ServicesCardsRegular}
                            children={<React.Fragment>
                                <p>{strings.servicesItemsWindows}</p>
                                <p>{strings.servicesItemsCommonPlaces}</p>
                                <p>{strings.servicesItemsPublicBuildings}</p>
                                <p>{strings.servicesItemsProfCleaning}</p>
                                <p>{strings.ServicesItemsHouseCleaning}</p>
                                <p>{strings.ServicesItemsPaintCleaning}</p>
                            </React.Fragment>
                            } />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <FlipCardService imgURL={"img/vysavac.jpg"} title={strings.ServicesCardsGeneral}
                            children={<React.Fragment>
                                <p>{strings.servicesItemsWindows}</p>
                                <p>{strings.servicesItemsCommonPlaces}</p>
                                <p>{strings.servicesItemsPublicBuildings}</p>
                                <p>{strings.servicesItemsProfCleaning}</p>
                                <p>{strings.ServicesItemsHouseCleaning}</p>
                                <p>{strings.ServicesItemsPaintCleaning}</p>
                            </React.Fragment>
                            } />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <FlipCardService imgURL={"img/snow-blower.jpg"} title={strings.ServicesCardsWinter}
                            children={<React.Fragment>
                                <p dangerouslySetInnerHTML={{ __html: strings.ServicesItemsWinterPlaces }} />
                                <p dangerouslySetInnerHTML={{ __html: strings.ServicesItemsWinterPark }} />
                                <p>{strings.ServicesItemsWinterPaths}</p>
                            </React.Fragment>
                            } />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <FlipCardService imgURL={"img/zahrada.jpg"} title={strings.ServicesCardsGarden}
                            children={<React.Fragment>
                                <p>{strings.ServicesItemsGarden}</p>
                            </React.Fragment>
                            } />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <FlipCardService imgURL={"img/vyskove_prace.jpg"} title={strings.servicesCardsHeights}
                            children={<React.Fragment>
                                <p>{strings.servicesItemsHeighs}</p>
                            </React.Fragment>
                            } />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <FlipCardService imgURL={"img/strojni_cisteni.jpg"} title={strings.servicesCardsMachines}
                            children={<React.Fragment>
                                <p>{strings.servicesItemsMachinesMachine}</p>
                                <p>{strings.servicesItemsMachinesBroom}</p>
                                <p>{strings.ServicesItemsMachinesFloors}</p>
                                <p>{strings.ServicesItemsMachinesCars}</p>
                                <p>{strings.ServicesItemsMachinesGrease}</p>
                            </React.Fragment>
                            } />
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                        <FlipCardService imgURL={"img/FacilityManagement_orig.jpg"} title={strings.servicesCardsFacilityManagement}
                            children={<React.Fragment>
                                <p>{strings.servicesItemsFacilityBuildings}</p>
                                <p>{strings.servicesItemsFacilitySecurity}</p>
                                <p>{strings.servicesItemsFacilityCleaning}</p>
                                <p>{strings.servicesItemsFacilityReception}</p>
                                <p>{strings.servicesItemsFacilityCustomization}</p>
                            </React.Fragment>
                            } />
                    </div>
                </div>
            </div>
            <div id="kontakt" className="container px-0 full-size text-center kontakt">
                <h2 className="h1-responsive fw-500 text-center my-4">{strings.contact}</h2>
                <p className="text-center w-responsive mx-auto mb-5">{strings.contactText}</p>

                <div className="row">
                    <div className="col-12 col-md-4 text-center">
                        <i className="fas fa-map-marker-alt fa-2x"></i>
                        <p>Liberec 46006, Rochlice VI, Gagarinova 759, CZ</p>
                    </div>
                    <div className="col-12 col-md-4 text-center">
                        <i className="fas fa-phone fa-2x"></i>
                        <p>+ 420 777 102 715</p>
                    </div>
                    <div className="col-12 col-md-4 text-center">
                        <i className="fas fa-envelope fa-2x"></i>
                        <p>kasmaclean@email.cz</p>
                    </div>

                </div>

            </div>
            <div className="container mb-3 px-0">
                <div className="row footer mx-0">
                    <div className="col-12 col-md-4 my-auto">
                        <p><i className="fas fa-user ml-3 mr-3" />Kasalová Martina</p>
                        <p><i className="far fa-building ml-3 mr-3" />IČO: 76084655</p>
                        <p><i className="far fa-building ml-3 mr-3" />DIČ: CZ7951102577</p>
                        <p><i className="fas fa-piggy-bank ml-3 mr-3" />Č.Ú: 6048110273/5500</p>
                    </div>
                    <div className="col">
                        <div className="mapouter">
                            <div className="gmap_canvas">
                                <iframe id="gmap_canvas" title="mapa"
                                    src="https://maps.google.com/maps?q=gagarinova%20759&t=&z=17&ie=UTF8&iwloc=&output=embed"
                                    frameBorder="0" scrolling="no" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    );
};

const FlipCardService = (props: { imgURL: string, title: string, children: any }) => {

    const [isFliped, setIsFliped] = useState<boolean>(false);

    return (
        <ReactCardFlip isFlipped={isFliped} flipDirection={"horizontal"}>
            <div className="col-sm-12 col-md-12 border-card"
                style={{
                    backgroundImage: `url('${props.imgURL}')`,
                    backgroundSize: "100% auto",
                    display: "inline-block"
                }}
                key="front"
                onClick={(event => {
                    event.preventDefault();
                    setIsFliped(!isFliped);
                })}>
                <div className="p-4" style={{ color: "white" }}>
                    <h3 className="subtitle">{props.title}</h3>
                </div>
            </div>
            <div className="col-sm-12 col-md-12 border-card" key="front"
                style={{
                    overflow: "hidden",
                    display: "inline-block"
                    // backgroundColor: "#BFDC9Ada"
                }} onClick={(event => {
                    event.preventDefault();
                    setIsFliped(!isFliped);
                })}>
                {props.children}
            </div>
        </ReactCardFlip>
    );
};

export default App;
