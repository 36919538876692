import LocalizedStrings from "react-localization";

export let strings = new LocalizedStrings({
    cze: {
        bannerTitle: "Pro Vaši společnost zajistíme komplexní úklidové práce a Facility Management na profesionální úrovni ve všech prostorech",
        homeTitle: "Kdo jsme",
        homeText: "Firma Kasmaclean působí v oblasti úklidových prací po celé České republice a v části Německa. Námi nabízené služby provádíme zodpovědně a s vysokou profesionální úrovní, kvalitou a rychlostí za velmi příznivé ceny. Na veškeré úklidové a čistící práce používáme Vámi schválené prostředky, které jsou taktéž schváleny hlavním hygienikem ČR/DE nebo Ministerstvem zdravotnictví. Poskytované služby/práce jsme schopni provádět dle Vašich požadavků v různém čase a to jak denně, tak i jednorázově, tak aby nebyl narušen provoz. Zakládáme si na pečlivosti, důslednosti, spolehlivosti, zodpovědnosti a proto všichni zaměstnanci jsou proškoleni s technologií prováděných prací, barevným kódováním a etickým kodexem firmy, tak aby každý náš klient byl maximálně spokojen.",
        about: "Klademe důraz na informovanost zákazníků o systémech práce i kontroly a dbáme na dobrou komunikaci mezi zákazníkem a naší společností. Toto vše je základem bezproblémového průběhu prací a rychlých reakcí na  změny v provozu nebo přání zákazníka. Harmonogram a rozsah úklidových prací Vám pomůžeme navrhnout po konzultaci s Vámi přesně dle Vašich potřeb a požadavků.",
        aboutTitle: "Co o nás můžeme říct",
        aboutMenu: "O nás",
        colorCode: "Používáme barevné kódování",
        colorCodeText: "V praxi to znamená, že pracujeme s pěti barvami kterými jsou označeny přípravky nebo malá úklidová technika a další pomůcky. Proč je důležité barevné kódování? Barevné kódování bylo zavedeno, aby se co nejvíce omezilo riziko přenosu infekcí . Proto jsou určeny barvy pro jednotlivé oblasti úklidu a v těchto barvách se používají pomůcky jako jsou např. vědra, utěrky, mopy",
        services: "V rámci  úklidu  zajišťujeme:",
        ServicesMenu: "Naše služby",
        ServicesCardsRegular: "Pravidelný úklid",
        ServicesCardsGeneral: "Generální úklid",
        ServicesCardsWinter: "Zimní úklid",
        ServicesCardsGarden: "Údržba zeleně",
        servicesCardsHeights: "Výškové práce",
        servicesCardsMachines: "Strojní čištění",
        servicesCardsFacilityManagement: "Facility Management",
        servicesItemsWindows: "Mytí oken",
        servicesItemsCommonPlaces: "Úklid společných prostor",
        servicesItemsPublicBuildings: "Úklid veřejných budov",
        servicesItemsProfCleaning: "Profesionální úklid",
        ServicesItemsHouseCleaning: "Úklid domů",
        ServicesItemsPaintCleaning: "Úklid po malování",
        ServicesItemsWinterPlaces: "Zimní údržba - Plochy v průmyslových objektech",
        ServicesItemsWinterPark: "Zimní údržba - parkoviště malých i velkých rozměrů",
        ServicesItemsWinterPaths: "Chodníky a přístupové cesty",
        ServicesItemsGarden: "Firmám nabízíme údržbu venkovních ploch v plném rozsahu včetně úklidu a odvozu organických odpadů.",
        servicesItemsHeighs: "Poskytujeme široký rozsah výškových prací v rámci pravidelných i generálních úklidů.",
        servicesItemsMachinesMachine: "Strojní čištění podlah",
        servicesItemsMachinesBroom: "Zametání podlah",
        ServicesItemsMachinesFloors: "Čištění výrobních a skladovacích prostor",
        ServicesItemsMachinesCars: "Autosalónů",
        ServicesItemsMachinesGrease: "Odmašťování výrobních a montážních hal",
        servicesItemsFacilityBuildings: "Správa budov",
        servicesItemsFacilitySecurity: "Bezpečnostní služby",
        servicesItemsFacilityCleaning: "Úklidové služby",
        servicesItemsFacilityReception: "Recepční služby",
        servicesItemsFacilityCustomization: "Jednotlivé služby přizpůsobujeme požadavkům zákazníka",
        servicesText: "pracovníky úklidu<br/>" +
            "náhradní pracovníky v případě nemoci apod.<br/>" +
            "profesionální úklidové prostředky a techniku",
        contactText: "Rádi Vám vypracujeme nezávazný cenový rozpočet. Máte zájem o nabídku či další informace ohledně našich služeb? Neváhejte nás kontaktovat a my Vám vše rádi vysvětlíme.",
        contact: "Kontaktujte nás",
        contactUsMenu: "Kontakt"
    },
    de: {
        bannerTitle: "Auf professionellem Niveau, in Räumen und Gebäuden aller Art, gewährleisten wir für Ihr Unternehmen komplexe Reinigungsarbeiten",
        about: "Wir achten darauf, dass unsere Kunden gut über unsere Arbeitsysteme sowie die Kontrolle informiert sind und dass die Kommunikation zwischen unseren Kunden und unserer Firma problemlos verläuft.  Dies alles bildet die Basis für einen problemlosen Verlauf der Reinigungsarbeiten und die Anpassung unseres Arbeitstempos, dem Wunsch unseres Kunden entsprechend.",
        aboutTitle: "Was können wir über uns sagen?",
        homeTitle: "Zu unserer Firma",
        aboutMenu: "Zu unserer Firma",
        homeText: "Unsere Firma Kasmaclean ist im Rahmen der Tschechischen Republik und teilweise auch in Deutschland tätig. Die von uns gewährleisteten Dienstleistungen erfolgen sorgfältig und auf hohem professionellen Niveau. Für alle unsere Reinigungsarbeiten werden die von Ihnen genehmigten Reinigungsmittel benutzt, die jedoch ebenfalls vom für Tschechien und Deutschland zuständigen Haupthygieniker sowie vom Gesundheitsministerium genehmigt sind. Wir sind bereit, unsere Dienstleistungen nach Ihrem Wunsch in diversen Terminen zu garantieren, sodass der Betrieb Ihrer Firma (oder etwa auch Ihres Haushalts) dadurch nicht gestört wird. Wir legen einen großen Wert auf  Sorgfältigkeit, Folgerichtigkeit, Zuverlässigkeit, und deshalb sind alle unsere Angestellten auch gut geschult, speziell im Bereich der Arbeitstechnologien, Farbenkodierungen sowie der Firmenethik, sodass jeder von unseren Kunden mit unseren Dienstleistungen völlig zufrieden sein kann.",
        colorCode: "Wir benutzen eine Farbenkodierung,",
        colorCodeText: "was in der Praxis heißt, dass wir mit fünf Farben arbeiten, mit denen unsere Reinigungsmittel, kleine Reinigungstechnik sowie weitere Arbeitsmittel bezeichnet werden. Warum ist eine solche Kodierung wichtig? Die Farbenkodierung wurde eingeführt, um das Risiko jeglicher Infektionsübertragungen zu minimalisieren. Deshalb werden die einzelnen Arbeitsmittel (z. B. Eimer, Besen, Mopps, Wischtücher etc.) für die jeweiligen Reinigungsbereiche mit Farben voneinander unterschieden.",
        services: "Im Rahmen der Reinigungsarbeiten gewährleisten wir:",
        ServicesMenu: "Unsere Dienstleistungen",
        ServicesCardsRegular: "Regelmäßige Reinigungsarbeiten",
        ServicesCardsGeneral: "Generalreinigung",
        ServicesCardsWinter: "Winterwartung",
        ServicesCardsGarden: "Grünflächenwartung",
        servicesCardsHeights: "Höhenarbeiten",
        servicesCardsMachines: "Maschinelle Reinigung",
        servicesCardsFacilityManagement: "Facility Management",
        servicesItemsWindows: "Fensterreinigung",
        servicesItemsCommonPlaces: "Reinigung gemeinsamer Räume",
        servicesItemsPublicBuildings: "Reinigung öffentlicher Gebäude",
        servicesItemsProfCleaning: "Professionelle Reinigung",
        ServicesItemsHouseCleaning: "Reinigung der Häuser",
        ServicesItemsPaintCleaning: "Reinigung nach Malerarbeiten",
        ServicesItemsWinterPlaces: "Winterwartung -<br/>" +
            "Flächen in wirtschaftlichen Objekten",
        ServicesItemsWinterPark: "Winterwartung -<br/>" +
            "große sowie kleinere Parkplätze",
        ServicesItemsWinterPaths: "Geh- und Zugangswege",
        ServicesItemsGarden: "Den Firmen bieten wie eine vollständige Wartung von Außenbereichen an, einschließlich der Reinigung und Entfernung von organischen Abfällen.",
        servicesItemsHeighs: "Im Rahmen der regelmäßigen sowie allgemeinen Reinigung bieten wir ein breites Spektrum an Höhenarbeiten an",
        servicesItemsMachinesMachine: "Maschinelle Bodenreinigung",
        servicesItemsMachinesBroom: "Böden fegen",
        ServicesItemsMachinesFloors: "Reinigung von Produktions- und Lagereinrichtungen",
        ServicesItemsMachinesCars: "Autohäuser",
        ServicesItemsMachinesGrease: "Entfettung von Produktions- und Montagehallen",
        servicesItemsFacilityBuildings: "Správa budov",
        servicesItemsFacilitySecurity: "Bezpečnostní služby",
        servicesItemsFacilityCleaning: "Úklidové služby",
        servicesItemsFacilityReception: "Recepční služby",
        servicesItemsFacilityCustomization: "Jednotlivé služby přizpůsobujeme požadavkům zákazníka",
        servicesText: "Reinigungsarbeiter<br/>" +
            "Ersatzkräfte, im Falle einer Erkrankung etc.<br/>" +
            "Professionelle Reinigungs-Mittel und -Technik",
        contactText: "Gerne erstellen wir Ihnen ein unverbindliches Preisbudget. Interessieren Sie sich für ein Angebot oder weitere Informationen zu unseren Dienstleistungen? Zögern Sie nicht, uns zu kontaktieren, gerne werden wir Ihnen alles erklären.",
        contactUsMenu: "Kontakt",
        contact: "Kontaktieren Sie uns"
    }
});